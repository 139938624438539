import React, { ChangeEvent } from "react";
import { Body2, CheckedCheckBox, Label1, UncheckedCheckBox, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";

import { useTheme, Checkbox } from "@mui/material";
import { Content } from "@iventis/translations";
import { tickboxStyle } from "./terms-and-condition-checkbox";

export const MarketingCheckbox: React.FC<{ onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void; className: string; value: boolean }> = ({
    onChange,
    className,
    value,
}) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();
    return (
        <Container>
            <StyledTaCInputContainer className={className}>
                <Checkbox
                    icon={<UncheckedCheckBox size={15} borderStyle="0px" backgroundColour={theme.secondaryColors.blank} />}
                    checkedIcon={<CheckedCheckBox size={15} tickColour="white" borderStyle="0px" backgroundColour={theme.primaryColors.focus} />}
                    onChange={onChange}
                    size="small"
                    style={tickboxStyle}
                    value={value}
                    id="marketingOptIn"
                    name="marketingOptIn"
                />
                <Label1 className="marketing-message">
                    <Body2>{translate(Content.login2.createAccount.marketing)}</Body2>
                </Label1>
            </StyledTaCInputContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTaCInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    margin-top: 29px;
    align-content: top;
    margin-bottom: 4px;

    .marketing-message {
        display: inline-block;
        text-align: "left";
    }
`;
