import { sessionReplayIntegration } from "../initialise-observability-and-monitoring";

/**
 * Sends the latest session replay data to Sentry and stops the session replay.
 *
 * Useful when user navigates away from the application
 */
export async function flushSessionReplay(): Promise<void> {
    await sessionReplayIntegration?.flush();
    await sessionReplayIntegration?.stop();
}
