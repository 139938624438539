import { styled } from "@iventis/styles";
import { loginCenterWidth } from "@iventis/styles/src/atomic-rules";

// Container for all page content
export const LoginPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    place-items: center;

    max-width: ${loginCenterWidth};
    margin: 0 auto;
    padding-inline: 16px;

    min-height: 100vh;
    min-height: 100dvh;
`;

// Container for the main login content
export const LoginPanelContainer = styled.div`
    display: flex;
    margin: auto;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
`;
