import axios from "axios";

// Switch to true if you wish to use your local databases instead of the deployed api
const useLocalApi = window.location.hostname === "localhost" && false;
export const devMode = window.location.hostname.toLowerCase() === "localhost" || window.location.hostname.toLowerCase() === "sign-up-dev.iventis.com";

export const baseURL = () => {
    if (useLocalApi) {
        return `http://localhost:5190`;
    }
    if (devMode) {
        return "https://api.admin-dashboard-dev.iventis.com";
    }
    return "https://api.admin-dashboard.iventis.com";
};

const initialiseAxios = () => axios.create({ baseURL: baseURL() });

export const api = initialiseAxios();
