import * as Yup from "yup";
import { regexDigit, regexLowercase, regexUppercase } from "@iventis/utilities";
import { Content } from "@iventis/translations";
import { basicTranslator, UseIventisTranslate } from "@iventis/translations/use-iventis-translate";

export const signupSchema = (translate: UseIventisTranslate = basicTranslator) =>
    Yup.object().shape({
        firstName: Yup.string().required(translate(Content.login.error.required)),
        lastName: Yup.string().required(translate(Content.login.error.required)),
        email: Yup.string().email(translate(Content.login.error.invalid)).required(translate(Content.login.error.required)),
        password: Yup.string()
            .required(translate(Content.login.error.required))
            .min(8, " ")
            .max(64, translate(Content.login.error.passwordTooLong))
            .matches(regexLowercase, " ")
            .matches(regexUppercase, " ")
            .matches(regexDigit, " "),
        termsAndConditions: Yup.boolean().oneOf([true], translate(Content.login.error.mustAgreeToTC)).required(translate(Content.login.error.mustAgreeToTC)),
    });
