import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Body2, styled } from "@iventis/styles";
import { Theme, css } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { regexUppercase, regexLowercase, regexDigit } from "@iventis/utilities";
import React from "react";

export const PasswordValidator: React.FC<{ password: string; stacked: boolean; className?: string }> = ({ password, className, stacked }) => {
    const translate = useIventisTranslate();
    return (
        <StyledList className={className} stacked={stacked}>
            <StyledListItem id={password.length >= 8 ? "valid" : ""}>
                {password.length >= 8 ? <ValidIcon /> : <NotValid />}
                <Body2>{translate(Content.login.passwordConditions.min8characters)}</Body2>
            </StyledListItem>
            <StyledListItem id={regexUppercase.test(password) ? "valid" : ""}>
                {regexUppercase.test(password) ? <ValidIcon /> : <NotValid />}
                <Body2>{translate(Content.login.passwordConditions.uppercaseLetter)}</Body2>
            </StyledListItem>
            <StyledListItem id={regexDigit.test(password) ? "valid" : ""}>
                {regexDigit.test(password) ? <ValidIcon /> : <NotValid />}
                <Body2>{translate(Content.login.passwordConditions.digit)}</Body2>
            </StyledListItem>
            <StyledListItem id={regexLowercase.test(password) ? "valid" : ""}>
                {regexLowercase.test(password) ? <ValidIcon /> : <NotValid />}
                <Body2>{translate(Content.login.passwordConditions.lowercaseLetter)}</Body2>
            </StyledListItem>
        </StyledList>
    );
};

const StyledList = styled.ul<{ stacked: boolean }>`
    ${({ stacked }) => `
        ${
            stacked
                ? `display: flex; flex-direction: column`
                : `    display: grid;
    grid-template-columns: 1fr 1fr;`
        }
    `}
`;

const StyledListItem = styled.li`
    align-items: center;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0;
    margin: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 20px;
    letter-spacing: 0.5px;
    &#valid {
        transition: all 0.5s ease-in;
    }
`;

const StyledValidIcon = styled(FontAwesomeIcon)`
    ${({ theme }: { theme: Theme }) => css`
        color: ${theme.secondaryColors.accept};
    `};
    margin-right: 10px;
    height: 20px;
    width: 14px;
`;

const StyledNotValidIcon = styled(FontAwesomeIcon)`
    ${({ theme }: { theme: Theme }) => css`
        color: ${theme.secondaryColors.grey};
    `};
    margin-right: 10px;
    height: 20px;
    width: 14px;
`;

const ValidIcon = () => <StyledValidIcon data-testid="check-mark-circle" icon={["fas", "circle-check"]} />;

const NotValid = () => <StyledNotValidIcon data-testid="cross-mark-circle" icon={["fas", "circle-check"]} />;
